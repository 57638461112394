

.MuiPagination-ul
    margin: 0
    display: flex
    padding: 0
    flex-wrap: inherit
    list-style: none
    align-items: center
.data-table
    svg
        cursor: pointer
    .MuiTableCell-root
        padding: 4px 4px!important
    th,td
        font-size: 13px!important
        min-width: 8rem !important
.containerMain
    max-width: 500px
    min-height: 100vh
    margin: 0px auto
    border: 1px solid #dddddd
    border-radius: 5px
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
.dialogBottom
    .MuiDialog-paperScrollPaper
        border-radius: 15px 15px 0px 0px
        bottom: 0px
        margin: 0px
        position: absolute
.dialogContent
    min-width: 500px
    padding: 30px

#collectionSlot
    margin: 0px auto
    max-width: 500px
    height: 100vh
    width: 100%
    border: 1px solid #dddddd
    overflow: auto
#collectionSlot .secondaryContainer
    padding:30px
    margin-top: 0px!important
    box-shadow: none
    height: 100%
    width: 100%
    overflow: auto
.datePro
    text-align: center
    padding:5px 15px
    color:#fff
    background: #E06358
    border-radius: 10px
.datePro p 
    margin: 3px 0px
    font-weight: 700
.dateLite
    text-align: center
    padding:5px 15px
    color:#E06358
    background: #fff
    border-radius: 10px
    border: 1px solid #E06358
.dateLite p 
    margin: 3px 0px
    font-weight: 700

@media (max-width: 500px) 
  .dialogContent
    min-width: 100vw



